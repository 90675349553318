import { useEffect, useState } from 'react';

export const useLoadingDelay = (delay: number) => {
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoading(false);
    }, delay);

    return () => clearTimeout(timeout); // Clean up the timeout on unmount
  }, [delay]);

  return showLoading;
};
