import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { token, userInfo } = useAuth(); // جلب المعلومات من سياق المصادقة
  const location = useLocation();

  // If not logged in, redirect to login page
  if (!token) {
    return <Navigate to="/login" />;
  }
  console.log('checking user ');
  console.log('checking user type ');
  console.log('checking user type and path ');
  // Handle route access based on user type
  if (userInfo?.user_type === 'student' && location.pathname.startsWith('/profile/instructor') ) {
    // If a student tries to access instructor routes, redirect to their profile
    return <Navigate to="/profile/student" />;
  } else if (userInfo?.user_type === 'instructor' && (location.pathname.startsWith('/profile/student') ||  location.pathname.startsWith('/profile'))) {
    // If an instructor tries to access student routes, redirect to instructor dashboard
    console.log('here');
    return <Navigate to="/instructor/dashboard" />;
  } else if (userInfo?.user_type === 'parent' && (location.pathname.startsWith('/instructor') || location.pathname.startsWith('/profile/student'))) {
    // If a parent tries to access instructor or student routes, redirect to parent profile
    return <Navigate to="/profile/parent" />;
  }

  // Otherwise, render the requested component
  return children;
};

export default PrivateRoute;
