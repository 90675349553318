import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { FormProvider } from "./context/FormContext";
import { Box } from '@mui/material';
import SideNav from "./pages/Profile/SideNav";
import Loading from "./pages/Home/component/Loading";
import { useLoadingDelay } from "./hooks/useLoadingDelay";
import { AttendanceScan } from './pages/StudentProfile/AttendanceScan';
import SessionProfileAttendance from "./pages/StudentProfile/Session/SessionAttendance";

// Lazy-loaded components
const Home = lazy(() => import("./pages/Home/Home"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const ProfileStudent = lazy(() => import("./pages/StudentProfile/Profile"));
const InstructorDashboard = lazy(() => import("./pages/Instructor/InstructorDashboard"));
const ProfileCourse = lazy(() => import("./pages/Profile/ProfileSession"));
const SessionProfile = lazy(() => import("./pages/StudentProfile/Session/SessionProfile"));

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isProfileRoute = location.pathname.startsWith("/profile") || location.pathname.startsWith("/instructor");

  return (
    <div className="flex h-full bg-red-50" >
      {isProfileRoute && <SideNav />}
      <div className="flex-grow h-full" style={{width:'99%'}} >
        {!isProfileRoute && <Navbar />}
        <main className="p-4">{children}</main>
        {!isProfileRoute && <Footer />}
      </div>
    </div>
  );
};

const App: React.FC = () => {
  const showLoading = useLoadingDelay(3000); // Minimum 1 second delay for loading spinner

  return (
    <AuthProvider>
      <FormProvider>
        <Router>
          <Layout>
            <Suspense fallback={showLoading ? <Loading /> : null}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
{/* it will addedd as soon as possible  */}
                {/* <Route path="/AttendanceScan" element={<AttendanceScan />} />  */}

                {/* Profile Routes */}
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile/student"
                  element={
                    <PrivateRoute>
                      <ProfileStudent />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile/student/course/:id"
                  element={
                    <PrivateRoute>
                      <ProfileCourse />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile/student/course/:courseId/session/:sessionId"
                  element={
                    <PrivateRoute>
                      <SessionProfile />
                    </PrivateRoute>
                  }
                />
                {/* this will get id of session it will get token of user then make request to add it to session
                then it will make hime come back if si=uccess to his ssession
                */}
                <Route
                  path="/profile/student/course/:courseId/session/:sessionId/attendance"
                  element={
                    <PrivateRoute>
                      <SessionProfileAttendance />
                    </PrivateRoute>
                  }
                />
                
                {/* Instructor Routes */}
                <Route
                  path="/instructor/dashboard"
                  element={
                    <PrivateRoute>
                      <InstructorDashboard />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      </FormProvider>
    </AuthProvider>
  );
};

export default App;
