import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, ListItemButton, ListItemIcon, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../../context/AuthContext';
import { logo } from '../../assets/inedx';

interface NavItem {
  label: string;
  path: string;
  icon: React.ReactNode;
}

// Define navItems
const navItems: NavItem[] = [
  { label: 'Profile', path: '/profile', icon: <PersonIcon /> },
  { label: 'Home', path: '/', icon: <HomeIcon /> },
];

const SideNav: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('AuthContext is not available');
  }

  const isChild = authContext.userInfo?.user_type === 'student';
  if (isChild) {
    navItems[0].path = '/profile/student';
  } else if (authContext.userInfo?.user_type === 'instructor') {
    navItems[0].path = '/instructor/dashboard';
  }

  const { logout } = authContext;
  const handleLogout = () => {
    logout();
  };

  return (
    <div className={`flex flex-col  ${isChild ? 'bg-yellow-50' : 'bg-gray-100'} transition-colors duration-300 max-sm:w-[40px] h-100 `} >
      {/* Logo Section */}
      <div className={`flex items-center justify-center p-4 max-sm:p-0  ${isChild ? 'bg-yellow-400' : 'bg-gray-800'}`}>
        <img
          src={logo}
          alt="Logo"
          className={`w-32 max-sm:w-[60px!important] h-auto  ${isChild ? 'text-yellow-900' : 'text-gray-100'}`}
        />
      </div>

      {/* Navigation Items */}
      <List className="flex-1 h-full">
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              className={`flex items-center p-3 rounded-md transition-colors duration-300
                ${isChild ? 'bg-yellow-200 hover:bg-yellow-300' : 'bg-gray-300 hover:bg-gray-400'}
              `}
            >
              <ListItemIcon className={`text-lg ${isChild ? 'text-yellow-700' : 'text-gray-700'}`}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                className={`ml-3 hidden md:block ${isChild ? 'text-yellow-700' : 'text-gray-700'}`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* Logout Button at the Bottom */}
      <div className="p-4">
        <IconButton
          color="inherit"
          onClick={handleLogout}
          className={`w-full rounded-md transition-colors duration-300
            ${isChild ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-gray-400 hover:bg-gray-500'}
          `}
        >
          <LogoutIcon className={`${isChild ? 'text-yellow-900' : 'text-black'}`} />
          <span className={`ml-2 hidden md:block ${isChild ? 'text-yellow-900/70' : 'text-black'}`}>Logout</span>
        </IconButton>
      </div>
    </div>
  );
};

export default SideNav;
