import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { logo } from '../assets/inedx';

const Navbar: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    // Handle the case where AuthContext is not available
    throw new Error('AuthContext is not available');
  }

  const { user, logout,userInfo } = authContext; // Destructure user and logout

  return (
    <header className="h-[105px] bg-white flex justify-between items-center px-5">
      {/* Logo */}
      <Link to="/" className="flex items-center">
        <img className="w-[132px] h-[93px] object-cover" src={logo} alt="Logo" />
      </Link>

      {/* Navigation Links */}
      <nav className="flex items-center gap-4">
        {user ? (
          <>
          {userInfo?.user_type === 'student' ? 
          <Link
          to="/profile/student"
          className="px-2 py-4 text-[#1a1a1a] text-xl font-normal font-['Ubuntu'] hover:bg-gray-200 rounded transition-colors duration-300"
        >
          Profile
        </Link>
          : 
          <Link
              to="/profile"
              className="px-2 py-4 text-[#1a1a1a] text-xl font-normal font-['Ubuntu'] hover:bg-gray-200 rounded transition-colors duration-300"
            >
              Profile
            </Link>
          }
            
            <button
              onClick={logout}
              className="px-2 py-4 text-[#1a1a1a] text-xl font-normal font-['Ubuntu'] hover:bg-gray-200 rounded transition-colors duration-300"
            >
              Log Out
            </button>
          </>
        ) : (
          <>
            <Link
              to="/login"
              className="px-2 py-4 text-[#1a1a1a] text-xl font-normal font-['Ubuntu'] hover:bg-gray-200 rounded transition-colors duration-300"
            >
              Log In
            </Link>
            <Link
              to="/register"
              className="px-8 py-4 rounded-[5px] border-2 border-[#FF5D30] text-[#1a1a1a] text-base font-normal font-['Ubuntu'] hover:bg-[#FF5D30] hover:text-white transition-colors duration-300"
            >
              Register
            </Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
